import React from 'react'
import FeatureSlider from '../../../components/slider/FeatureSlider'
import styles from '../styles/FeatureSection.module.css'
import fireIcon from '../../../assets/image/landing-images/fi_14261136.png'
import { FaCircleChevronRight } from "react-icons/fa6";
import arrow from '../../../assets/image/landing-images/green_arrow.png'
import VideoPlayIcon from '../../../assets/image/landing-images/vdo-play-icon.png'

const FeatureSection = () => {
    return (
        <div>
            <section className={styles.features_main_container}>
                <div className={styles.slider_header}>
                    <img src={fireIcon} alt="" />
                    <h6>Market Spotlight</h6>
                </div>
                <FeatureSlider />
                <div className={styles.features_desc_main_section}>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className={styles.features_desc_text}>
                                    <h1><span className={styles.desc_text_feature}>Features</span></h1>
                                    <h1> Discover Dips </h1>
                                    <p>
                                        Discover dips enables you to identify potential buying opportunities in the market.
                                        By detecting significant pullbacks and undervalued stocks, this feature empowers you to make informed investment decisions.
                                        Stay ahead by leveraging historical trends and data-driven insights to capitalize on market dips with confidence.
                                    </p>
                                    <h1> Track Trends </h1>
                                    <p>
                                        Track Trends enables you analyze stock movement patterns by identifying upward, downward, and sideways trends based on historical and current market prices.
                                        Gain a clearer understanding of market momentum and make informed decisions with data-driven insights into long-term and short-term trends.
                                    </p>
                                    <h1> Stock <span
                                        className={styles.desc_text_degree}>360°</span> </h1>
                                    <p>
                                        Stock 360 offers a comprehensive analysis of individual stocks from multiple perspectives.
                                        By examining historical performance, trend patterns,  and other key indicators, this feature gives you a well-rounded view to support informed investment decisions.
                                    </p>
                                    <div className={styles.features_read_more}>
                                        <img src={arrow} alt=""/>
                                        <p>Read More</p>
                                    </div>
                                    <div className={styles.trading_btn}>
                                        <span>Start Trading</span>
                                        <p><FaCircleChevronRight/></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className={styles.features_img_section}>
                                    <div className={styles.video_ctrl_txt_btn}>
                                        <img src={VideoPlayIcon} alt=""/>
                                        <p>Watch our product video</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FeatureSection
