import React from 'react'
import styles from '../styles/SaleSection.module.css'
import mobileTrading from '../../../assets/image/landing-images/mobile-trading-img.png'
import laptopTrading from '../../../assets/image/landing-images/laptop-trading-img.png'
import VideoPlayIcon from '../../../assets/image/landing-images/vdo-play-icon.png'

const SaleSection = () => {
    return (
        <div>
            <section className={styles.sale_section_container}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-7">
                            <div className={styles.progress_bar_section}>
                                <h1>Start <span>Free</span> Trial!</h1>
                                <div></div>
                                <button>Sign Up</button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-5">
                            <div className={styles.sale_section_header}>
                                <h1>Start <br />Trading with <span className={styles.trade}>Thick</span><span className={styles.xcel}>-Wallet</span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-7">
                            <div className={styles.mobile_trading_wrapper}>
                                <img src={mobileTrading} alt="" />
                                <div className={styles.mobile_trading_header}>
                                    <h6>Unlock your potential in </h6>
                                    <h3>Trading</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-5">
                            <div className={styles.laptop_trading_wrapper}>
                                <img src={laptopTrading} alt="" />
                                <div className={styles.vdo_play_btn}>
                                    <p>Explore <br />Video</p>
                                    <img src={VideoPlayIcon} alt="" className='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SaleSection
