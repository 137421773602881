import React from 'react';
import styles from './Dashboard.module.css';
import FeaturesCard from './FeaturesCard';
import dipsIcon from '../../assets/image/dashboard-images/Vector.png'
import trendsIcon from '../../assets/image/dashboard-images/graph 1.png'
import stocksIcon from '../../assets/image/dashboard-images/global-economy.png'
import InsightsCard1 from './InsightsCard1';
import InsightsCard2 from './InsightsCard2';
import etfIcon from '../../assets/image/dashboard-images/pie-chart 1.png'
import { FaCircleChevronUp } from "react-icons/fa6";

export const Dashboard = (props) => {
  const feature_tiles = [
    { id: 0, icon: dipsIcon, title: "Dips", sharename: 'Dow Jones', desc: "Ut enim ad minima veniam, quis nostrum exercitationem ullam" },
    { id: 1, icon: trendsIcon, title: "Trends", sharename: 'Axis Cap', desc: "Sed ut perspiciatis unde  corporis suscipit laboriosam, nisi ut aliquid ex " },
    { id: 2, icon: stocksIcon, title: "Stocks 360", lock:true, sharename: 'BSE', desc: "Ut enim ad minima veniam, quis nostrum exercitationem ullam" },
    { id: 3, icon: etfIcon, title: "ETFs", lock:true, sharename: 'Dow Jones', desc: "Sed ut perspiciatis unde  corporis suscipit laboriosam, nisi ut aliquid ex " },
     ];


  return (
    <div className={styles.dashboard_content_section}>

      <div className='container'>
        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.wrapper}`}>
          <div className={styles.dashboard_title}>
            <h4>Features</h4>
            <span><FaCircleChevronUp /></span>
          </div>


          <div className='feature-tile-wrapper col-12'>
            <div className='row'>
              {/* {
                feature_tiles.map((tile, index) => {
                  return (
                    <div className="col-md-3" key={index}>
                      <div className={`card ${styles.card}`} onClick={() => props.navigationCallback(index)}>
                        <img alt='feature-symbol' src={require('../../assets/image/investment.png')} className={`card-img-left ${styles.iconImage}`} />
                        <div className="card-body" >
                          <h5 className="card-title">{tile.title}</h5>
                          <p className="card-text">{tile.desc}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              } */}
              {
                feature_tiles.map((tile, index) => (
                  <div className='col-lg-3 col-md-6 col-sm-12' key={index} onClick={() => props.navigationCallback(index)}>
                    <FeaturesCard
                      icon={tile.icon}
                      featureHeading={tile.title}
                      shareName={tile.sharename}
                      cardDesc={tile.desc}
                      lock={tile.lock}
                    />
                  </div>
                ))
              }

            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className={`col-lg-12 col-md-12 col-sm-12 ${styles.wrapper}`}>
          <div className={styles.dashboard_title}>
            <h4>Insights</h4>
          </div>

          <div className={styles.insights_tile_wrapper}>
            <div className='row'>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                <InsightsCard1 />
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                <InsightsCard2 />
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                <InsightsCard1 />
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                <InsightsCard2 />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
