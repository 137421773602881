import React from 'react'
import styles from '../modal/Modal.module.css'
import thickwalletFullLogo from '../../assets/image/logo/thickWalletFullLogo.png'
import { IoClose } from "react-icons/io5";

const Modal = ({ isOpen, onClose, children }) => {

    if (!isOpen) return null;

    return (
        <div className={styles.modal}>
            <div onClick={onClose} className={styles.overlay}></div>
            <div className={styles.modal_btn_wrapper}>
                <button className={styles.close_button} onClick={onClose}><IoClose /></button>

                <div className={styles.modal_content}>
                    <div className={styles.modal_img_wrapper}>
                        <img src={thickwalletFullLogo} alt="" />
                    </div>
                    <div className={styles.modal_body}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
