import React from 'react'
import styles from '../styles/ServiceSection.module.css'

import graph from '../../../assets/image/landing-images/service_graph.png'
import ArrowIcon from '../../../assets/image/landing-images/service_arrow.png'

const ServiceSection = () => {
    return (
        <div>
            <section className={styles.service_main_wrapper}>
                <div className={styles.service_sub_desc}>
                    <div className={styles.service_about}>
                        <h1>
                            <span>What <span className={styles.about_styled_text}>we do.</span></span> </h1>
                        <p>At <span className={styles.about_styled_text}>ThickWallet</span>, we provide actionable insights and data-driven analysis to help stock traders make informed decisions. By analyzing market trends, stock performance, and key indicators, we simplify complex data into meaningful, easy-to-understand guidance.</p>

                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                                <div className={styles.service_count}>
                                    <h1>10K<span>+</span></h1>
                                    <p>Users trading smarter</p>
                                </div>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                                <div className={styles.service_count}>
                                    <h1>3000<span>+</span></h1>
                                    <p>Stocks analysed everyday</p>
                                </div>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                                <div className={styles.service_count}>
                                    <h1>100<span>+</span></h1>
                                    <p>Insights identified everyday</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.service_graph_section}>
                    <img src={graph} alt="" className={styles.service_graph_img} />

                    <div className={styles.service_main_desc}>
                        <h1>Get insights that turn opportunities into <span>success</span></h1>
                        <p>
                            We empower you to trade smarter, navigate the market confidently, and maximize your potential for success. Whether you’re a beginner or an experienced trader, ThickWallet equips you with insights to spot opportunities and achieve your market goals.
                        </p>
                    </div>
                    <div className={styles.main_desc_footer}>
                        <div className={styles.desc_footer_wrapper}><h4>Over <span>3000</span> stocks analyzed everyday</h4></div>
                        <img src={ArrowIcon} alt="" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ServiceSection
