import React from 'react'
import styles from '../styles/TradingFeatures.module.css'
import greenArrow from '../../../assets/image/landing-images/green_arrow.png'
import marginTradingIcon from '../../../assets/image/landing-images/increasement 1.png'
import mobileTradingIcon from '../../../assets/image/landing-images/trading 1.png'
import automatedTradingIcon from '../../../assets/image/landing-images/stock-market 1.png'
import customerSupportIcon from '../../../assets/image/landing-images/customer-support 1.png'
import diverseInvestmentIcon from '../../../assets/image/landing-images/profit 1.png'
import marketIndicesIcon from '../../../assets/image/landing-images/market-analysis 1.png'
import safetyIcon from '../../../assets/image/landing-images/security 1.png'
import automizationIcon from '../../../assets/image/landing-images/automization.png'
import analyticsIcon from '../../../assets/image/landing-images/analytics.png'
import tabletImg from '../../../assets/image/landing-images/tablet_bg.png'
import girlImg from '../../../assets/image/landing-images/girl_bg.png'

const TradingFeatures = () => {
    return (
        <div className={styles.mainfeature_wrapper}>
            <section className={styles.trading_features_container}>
                <div className={styles.trading_features_header_wrapper}>
                    <div className={styles.trading_features_header}>
                        <h1>What we <span>Offer</span></h1>
                    </div>
                    <p>Unlock your trading potential with our powerful features.</p>
                </div>

                <div className={styles.trading_feature_body}>
                    <div className="row mt-5">
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={marginTradingIcon} alt="" />
                                    <h3>Daily Market Insights</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Stay ahead of the curve with fresh, data-driven insights delivered every day to help you make smarter trading decisions. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={mobileTradingIcon} alt="" />
                                    <h3>Mobile Accessibility</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Access powerful trading tools and insights on the go with our intuitive mobile app, designed to keep you connected anytime, anywhere. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={automatedTradingIcon} alt="" />
                                    <h3>Custom Watchlist</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Keep a close eye on your favorite stocks by creating a personalized watchlist, so you never miss important movements or opportunities. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={customerSupportIcon} alt="" />
                                    <h3>Dedicated Customer Support</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Get prompt, reliable assistance from our expert support team, ready to help you navigate your trading journey whenever you need it.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={diverseInvestmentIcon} alt="" />
                                    <h3>Flexible Plans</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Choose from a variety of subscription plans designed to meet the unique needs of every trader, whether you’re just starting out or an experienced investor. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className={styles.trading_feature_points}>
                                <div className={styles.feature_header}>
                                    <img src={marketIndicesIcon} alt="" />
                                    <h3>Continuous Innovation</h3>
                                </div>
                                <div className={styles.feature_body}>
                                    <p>Benefit from an ever-expanding suite of advanced features and tools, with regular updates to enhance your trading experience. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className={styles.dashed_line}></div>

            <section className={styles.trading_investment_features}>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-7'>
                        <div className={styles.inv_return_content}>
                            <h1>Maximize <br /><span>Your Potential</span></h1>
                            <p>Trade is essential for keeping a competitive global economy and lowers the prices of goods internationally .</p>
                        </div>
                        <div className={styles.inv_return_footer}>
                            <button>GET STARTED</button>

                            <div className={styles.offerings_wrapper}>
                                <div className={styles.offering_container}>
                                    <img src={safetyIcon} alt="" />
                                    <span>Safety</span>
                                </div>
                                <div className={styles.offering_container}>
                                    <img src={automizationIcon} alt="" />
                                    <span>automization</span>
                                </div>
                                <div className={styles.offering_container}>
                                    <img src={analyticsIcon} alt="" />
                                    <span>analytics</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <div className={styles.inv_bg_wrapper}>
                            <img src={tabletImg} alt="" className={styles.inv_img1} />
                            <img src={girlImg} alt="" className={styles.inv_img2} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TradingFeatures
