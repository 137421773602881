import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchDipStocks, selectDipStocks } from "./DipSlice";
import { selectUserWatchlist, selectIndices } from "../watchlist/WatchlistSlice";
import styles from "./Dip.module.css";
import { Table, Pagination } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "../../util/css/Table.css";
import Grid from "../../components/grid/Grid";
import closeIcon from '../../assets/image/dashboard-images/cross 1.png'
import { IoChevronDownCircle } from "react-icons/io5";

export const Dip = () => {
  const [dipThreshold, setDipThreshold] = useState(1);
  const [dipDuration, setDipDuration] = useState(3);
  const dispatch = useDispatch();
  const dip_stocks = useSelector(selectDipStocks);
  const watchlists = useSelector(selectUserWatchlist) || [];
  const indices = useSelector(selectIndices) || [];
  const months_array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [watchlistSelected, setWatchlistSelected] = useState('');
  const [indexSelected, setIndexSelected] = useState('');

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const [sortValue, setSortValue] = useState("null");
  const [pageSizeValue, setPageSizeValue] = useState("null");


  const handleSortChange = (value) => {
    setSortValue(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSizeValue(value);
  };

  useEffect(() => {
    //TO DO - Validate selection. Handle edge cases/ watchlist/index
    //Set a default watchlist selected
    setIndexSelected(indices[0].indexUId);
    console.log(indices[0].indexUId);
    const req = {
      months: dipDuration,
      threshold: dipThreshold,
      indexUId: indices[0].indexUId
    };
    dispatch(fetchDipStocks(req));
  }, [dispatch]);


  const fetchCustomDipList = () => {
    const req = {
      months: parseInt(dipDuration, 10),
      threshold: parseInt(dipThreshold, 10),
    };
    if (watchlistSelected) req.watchlistUId = watchlistSelected;
    if (indexSelected) req.indexUId = indexSelected;

    console.log(req)
    dispatch(fetchDipStocks(req));
  }

  const handleGroupSelection = (value, flag) => {
    if (flag === "W") {
      setWatchlistSelected(value);
      setIndexSelected("");
    } else {
      setWatchlistSelected("");
      setIndexSelected(value);
    }
  }

  const toggleAdvancedSearch = (currentAdvancedSearchState) => {
    setShowAdvancedSearch(!currentAdvancedSearchState);
  };

  const closeAdvancedSearch = () => {
    setShowAdvancedSearch(false);
  };

  return (
    <div className="container">
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.filter_section} ${showAdvancedSearch ? styles.adv_active : ""}`}>
          <div className={`form-row ${styles.inp_btn_wrapper}`}>
            <div className={styles.filter_inputs_wrapper}>
              <div className={`${styles.watchlist_dropdown_wrapper}`}>
                {/* <label>Watchlist</label> */}
                <select className={`${watchlistSelected === '' ? styles.grey_text : styles.black_text}`} value={watchlistSelected} onChange={(event) => handleGroupSelection(event.target.value, 'W')}>
                  <option disabled value=''>Select Watchlist</option>
                  {
                    watchlists.map((item, index) => {
                      return (
                        <option key={index} value={item.watchlistUId}>{item.watchlistName}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className={styles.filter_dashed_line}></div>

              <div className={styles.watchlist_dropdown_wrapper}>
                {/* <label>Index</label> */}
                <select className={`${indexSelected === '' ? styles.grey_text : styles.black_text}`} value={indexSelected} onChange={(event) => handleGroupSelection(event.target.value, "I")}>
                  <option disabled value=''>Select Index</option>
                  {
                    indices.map((item, index) => {
                      return (
                        <option key={index} value={item.indexUId}>{item.indexName}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className={styles.filter_dashed_line}></div>

              <div className={`${styles.search_input_wrapper}`}>
                {/* <label>Dip</label> */}
                <input type="number" className="form-control" placeholder="No of Months" value={dipThreshold} onChange={(event) => setDipThreshold(event.target.value)} />
              </div>

              <div className={styles.filter_dashed_line}></div>

              <div className={`${styles.search_input_wrapper}`}>
                {/* <label>Months</label> */}
                <select className={`${dipDuration === '' ? styles.grey_text : styles.black_text}`} value={dipDuration} onChange={(event) => setDipDuration(event.target.value)}>
                  <option disabled value=''>Dips %</option>
                  {
                    months_array.map((month) => {
                      return (
                        <option key={month} value={month}>{month}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className={styles.filter_dashed_line}></div>

            </div>
            <div className={`${styles.centered_button}`}>
              <input type="button" value='SUBMIT' className={styles.filter_btn} onClick={fetchCustomDipList}></input>
              <button className={styles.filter_clear_btn} >CLEAR</button>
              <button className={styles.adv_search_btn} onClick={() => toggleAdvancedSearch(showAdvancedSearch)}>Advanced Search</button>
              <span className={styles.adv_dropdown_btn} onClick={() => toggleAdvancedSearch(showAdvancedSearch)}><IoChevronDownCircle /></span>
            </div>
          </div>
        </div>

        <div className={`${styles.adv_search_section} ${showAdvancedSearch ? styles.active : styles.inactive}`}>
          <div className={styles.adv_option_container}>
            <div className={styles.adv_input_search}>
              <input placeholder="Search" />
              <button>Go</button>
            </div>
            <div className={styles.adv_dashed_line}></div>

            <div className={styles.adv_dropdown_wrapper}>
              <select
                className={`${sortValue === 'null' ? styles.grey_text : styles.black_text}`}
                value={sortValue}
                onChange={(e) => handleSortChange(e.target.value)}
              >
                <option disabled value="null">Sort</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
            <div className={styles.adv_dashed_line}></div>

            <div className={styles.adv_dropdown_wrapper}>
              <select
                className={`${pageSizeValue === 'null' ? styles.grey_text : styles.black_text}`}
                value={pageSizeValue}
                onChange={(e) => handlePageSizeChange(e.target.value)}
              >
                <option disabled value="null">Page Size</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
            <div className={styles.adv_dashed_line}></div>
          </div>
          <div className={styles.close_btn_wrapper}>
            <button onClick={closeAdvancedSearch}>
              Close
              <img src={closeIcon} />
            </button>
          </div>
        </div>

        <div>
          {dip_stocks && (
            <>
              <Grid source={dip_stocks} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
