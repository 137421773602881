import React from 'react'
import styles from './FeaturesCard.module.css'
import downArrow from '../../assets/image/dashboard-images/image 8.png'
import { IoMdArrowDropup } from "react-icons/io";

const FeaturesCard = ({ icon, featureHeading, shareName, cardDesc,lock }) => {
    return (
        <div className={`${styles.features_card_wrapper} ${lock ? styles.locked : ''}`}>
            <div className={styles.features_card_top}>
                <div className={styles.card_top_header}>
                    <img src={icon} alt="" />
                    <h4>{featureHeading}</h4>
                </div>
                <img src={downArrow} alt="" />
            </div>
            <div className={styles.card_dashed_line}></div>
            <div className={styles.features_card_body}>
                <span><IoMdArrowDropup /></span>
                <div className={styles.card_body_desc}>
                    <h5>{shareName}</h5>
                    <p>{cardDesc}</p>
                </div>

            </div>
        </div>
    )
}

export default FeaturesCard