import React, { useEffect, useState } from "react";
import styles from "./Watchlist.module.css";
import dashboardLogo from '../../assets/image/logo/thickWalletFullLogo.png'
import { IoChevronDownOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserWatchlist,
  selectAllStocksForWatchlist,
} from "./WatchlistSlice";
import { getUserWatchlist, getAvailableStocks, updateUserWatchlist, deleteFromUserWatchlist, getAvailableIndices } from "./WatchlistSlice";

export const Watchlist = () => {
  const dispatch = useDispatch();
  const [isOpenIndex, setIsOpenIndex] = useState(-1)
  const user_watchlist_stocks = useSelector(selectUserWatchlist);
  const all_watchlist_stocks = useSelector(selectAllStocksForWatchlist);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    dispatch(getUserWatchlist());
    dispatch(getAvailableStocks());
    dispatch(getAvailableIndices());
  }, [dispatch]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const addStockToWatchlist = (stock, event) => {
    const li = event.target.closest('li');
    const id = li.getAttribute('id');
    const obj = {
      stock: stock,
      id: id
    }
    dispatch(updateUserWatchlist(obj));
  }

  const deleteStockFromWatchlist = (stock, event) => {
    const li = event.target.closest('ul');
    const id = li.getAttribute('w-id');
    const obj = {
      stock,
      id
    }
    dispatch(deleteFromUserWatchlist(obj));
  }

  const customOption = ({ data, innerProps, selectProps }) => {
    const handleOptionClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <div
        {...innerProps}
        onClick={handleOptionClick}
        className={styles.option}
      >
        <button>{data.stockSymbol}</button>
        <button
          type="button"
          onClick={(e) => {
            addStockToWatchlist(data, e)
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {/* + ADD */}
          <FaPlus />
        </button>
      </div>
    );
  };

  const renderIndividualWatchList = (item, index) => {
    return (
      <>
        <div className={styles.list_title} onClick={() => { if (isOpenIndex === index) { setIsOpenIndex(-1); } else { setIsOpenIndex(index) } }}>
          {item.watchlistName}
          <button >
            {/* <FontAwesomeIcon icon={faAngleDoubleDown} /> */}
            <IoChevronDownOutline />
          </button>
        </div>
        {isOpenIndex === index ? (
          <>
            <div className={styles.search}>
              <Select
                value={selectedOption}
                onChange={handleChange}
                options={all_watchlist_stocks}
                isSearchable={true}
                placeholder="Search..."
                components={{ Option: customOption }}
                onMenuOpen={() => setSelectedOption(null)} // Clear selected option when the menu is opened
                onMenuClose={() => { }} // Prevent the menu from closing
              />
            </div>
            <div className={styles.added_stocks}>
              <ul w-id={item.watchlistId}>
                {item.stocks.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        {item.stockSymbol}
                      </span>
                      <button onClick={(event) => deleteStockFromWatchlist(item.stockId, event)}>
                        {" "}
                        <FontAwesomeIcon icon={faTrash} />
                        {/* DELETE */}
                      </button>

                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.feature_head}>
        <img src={dashboardLogo} alt="" />
      </div>

      <div className={styles.sidebar_accordion_container}>
        <ul className={styles.list_style}>
          {user_watchlist_stocks.map((item, index) => {
            return <li key={index} id={item.watchlistId}>{renderIndividualWatchList(item, index)}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};
