import React, { useState } from "react";
import styles from "./Header.module.css";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, clearSession } from "../../features/login/LoginSlice";
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import notificationIcon from '../../assets/image/dashboard-images/bell_icon.png'

import { HiMiniBars3BottomLeft } from "react-icons/hi2";

const Header = ({showSidebar, toggleSidebar}) => {
  const userObject = useSelector(selectUser);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const doLogOut = () => {
    //clear userObject
    dispatch(clearSession());
    setTimeout(() => {
      history.push('/landing-page');
    }, 0);
  }

  return (
    <div className={`row ${styles.wrapper}`}>
      {/* <div className="col-lg-6 col-md-6 col-sm-6">
        <div className={styles.header_left}>
          <img src="logo.png" alt="Logo" className={styles.logo} />
          <h1 className={styles.title}> Trader Pro</h1>
        </div>
      </div> */}
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className={styles.header_wrapper}>
          <div className={styles.header_left}>
            <button className={styles.hamburger_btn} onClick={toggleSidebar}>
              <HiMiniBars3BottomLeft />
            </button>
          </div>

          <div className={styles.header_right}>
            <div className={styles.notification_icon_wrapper}>
              <div className={styles.notification_count}>5</div>
              <img src={notificationIcon} />
            </div>
            <div className={styles.user_profile_btn}>
              <img
                src={require("../../assets/image/user.png")}
                alt="Profile Icon"
                className={styles.profile_icon}
                onClick={() => setIsModalOpen(!isModalOpen)}
              />
              {isModalOpen && (
                <div className={`modal fade show ${styles.modal_size}`} style={{ display: "block" }}>
                  <div className={`modal-dialog  ${styles.modal_top_right}`}>
                    <div className="modal-content">
                      <div className="modal-body">
                        <p>My Profile</p>
                        <p onClick={doLogOut}>Logout</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className={styles.username_section}>Hello <span>{userObject?.userDetails?.firstName}</span></p>
            <button className={styles.upgrade_btn}>UPGRADE</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
